import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostItem from "../components/postitem";

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = this.props.data.site.siteMetadata.title

        let postData = {
            route: post.frontmatter.path,
            title: post.frontmatter.title,
            date: post.frontmatter.date,
            category: post.frontmatter.category.split(",").map(cat => cat.trim()),
            html: post.html
        }

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                />

                <div className="app-post">
                    <PostItem post={postData}></PostItem>
                </div>

            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        path
        title
        date(formatString: "MMMM DD, YYYY")
        category
        keywords
      }
    }
  }
`
