import React, { Component } from 'react';
import { Link } from "gatsby"

export default class PostItem extends Component {

    getFormattedDate(date) {
        let dateObj = new Date(date);
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        let day = dateObj.getDate();
        let month = months[dateObj.getMonth()];
        let year = dateObj.getFullYear();

        return `${month} ${day}, ${year}`;
    }

    render() {
        let { route, title, date, category, html } = this.props.post;
        return (
            <article className="post">
                <div className="post-header">
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-1">
                                <h2><Link to={route}>{title}</Link></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="post-metadata">
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-1">
                                <div className="post-metadata-date">{this.getFormattedDate(date)}</div>
                                <div className="post-metadata-comments">Comments</div>
                                <div className="post-metadata-category">Posted in:&nbsp;
                                    {category.map((cat, i) => <span key={cat}>
                                    {i > 0 && ", "}
                                    <Link key={cat} to={`/blog/categories/${cat}`}>{cat}</Link>
                                </span>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="post-body">
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-1">
                                <div dangerouslySetInnerHTML={{ __html: html }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="post-footer">
                    <div className="container px-0">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-1">
                                <div className="footer-comments">Comments</div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}
